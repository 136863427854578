<template>
    <section class="tree-root" :class="{ 'tree-root-no-padding': noPadding }">
        <div v-if="!noHeader" class="d-flex align-items-center justify-content-between pb-2 tree-flower">
            <div>
                <slot name="control-button">
                    <control-button @click.native="collapseBranch" :active="collapsed"/>
                </slot>
                <slot name="control-addon"></slot>
            </div>
            <slot name="control-addon-end"></slot>
        </div>
        <div :class="collapsed ? 'tree-collapsed' : ''">
            <slot></slot>
        </div>
    </section>
</template>

<script>
import ControlButton from '@/views/components/TreeLayout/components/ControlButton.vue';

export default {
    components: {
        ControlButton
    },
    props: ['noHeader', 'noPadding'],
    data() {
        return {
            collapsed: true,
        }
    },
    methods: {
        collapseBranch() {
            this.collapsed = !this.collapsed;
        }
    }
}
</script>

<style lang="scss">
$borderColor: #EEE;

.tree-root {
    &.tree-root-no-padding {
        .tree-branch {
            padding-left: 0 !important;

            .tree-leaf {
                padding-left: 0 !important;
            }
        }
    }

    .tree-flower {
        min-height: 40.5px;

        + div {
            padding-left: 1.7rem !important;
        }
    }

    .tree-branch {
        padding-left: 30px;
        padding-bottom: 2rem;
        overflow: hidden;

        .tree-leaf {
            background-color: white;
            width: 100%;
            position: relative;
            padding-left: 10px;
        }
    }

    .tree-branch .tree-leaf .tree-root {
        > .tree-flower {
            display: none !important;
        }

        > .tree-flower + div {
            padding-left: 0 !important;
        }
    }

    .tree-branch:first-child {
        .tree-flower {
            display: none !important;
        }

        .tree-flower + div {
            padding-left: 0 !important;
        }
    }

    .tree-branch:not(:last-child) {
        border-left: 1px solid $borderColor;
        border-bottom: 1px solid $borderColor;
        border-radius: 0 0 0 15px;
    }

    .tree-branch:not(:first-child) {
        margin-top: -16px;
        display: none;
    }

    .tree-leaf > div:not(.tree-collapsed) .tree-root > .tree-collapsed .tree-branch:not(:first-child) {
        display: none !important;
    }

    .tree-collapsed {
        .tree-branch:not(:first-child) {
            display: block;
        }

        > .tree-root > .tree-collapsed {
            .tree-branch:not(:first-child) {
                display: block !important;
            }
        }
    }
}
</style>
