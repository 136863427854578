<template>
    <section id="dashboard-new-form">
        <b-card no-body>
            <b-card-body class="p-0 d-flex flex-column position-relative">
                <loading v-if="loading"/>
                <section class="p-4">
                    <div class="d-flex justify-content-between align-items-center w-100">
                        <h3 class="text-left text-secondary font-weight-bolder">
                            {{ $route.name === 'responses/new' ? 'Nova Resposta' : 'Editar Resposta' }}
                        </h3>
                        <a v-b-modal.saveSubjectModal>Adicionar assunto</a>
                        <save-subject-modal @input="e => subjects.push(e)"/>
                    </div>

                    <div class="d-flex flex-column my-4">
                        <h6 class="text-black text-left font-weight-bold mb-2">
                            Classificação
                        </h6>
                        <div class="d-flex">
                            <input v-model="answer.name" class="form-control mr-3" placeholder="Nome da Resposta"
                                   type="text">
                            <select v-model="answer.subject_id" class="form-control mr-3">
                                <option :value="null" selected>Assunto</option>
                                <option v-if="subjects.length === 0" disabled :value="-1">Nenhum assunto encontrado</option>
                                <option
                                    v-for="subject in subjects"
                                    :key="`subject-options-${subject._id}`"
                                    :value="subject._id"
                                >
                                    {{ subject.name }}
                                </option>
                            </select>
                            <select v-if="user.can_shot" v-model="answer.campaign_id" class="form-control mr-3">
                                <option :value="null" selected>Campanha</option>
                                <option
                                    v-for="campaign in campaigns"
                                    :key="`campaign-options-${campaign._id}`"
                                    :value="campaign._id"
                                >
                                    {{ campaign.name }}
                                </option>
                            </select>
                            <select v-model="answer.active" class="form-control">
                                <option :value="true" selected>Ativado</option>
                                <option :value="false">Desativado</option>
                            </select>
                        </div>
                    </div>

                    <div class="row d-flex justify-content-start">
                        <div class="col-auto d-flex justify-content-start align-items-center my-1">
                            <b-form-checkbox 
                                v-model="recursiveAnswers" 
                                :class="recursiveAnswers ? 'text-blue' : ''"       
                                name="check-button" 
                                size="lg" 
                                switch
                            >
                                Habilitar Respostas em Sequência
                            </b-form-checkbox>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 my-1">
                            <select class="form-control" v-model="answer.frequency">
                                <option :value="null" disabled>Selecione uma opção</option>
                                <option value="suggest">Sugerir para o Atendente</option>
                                <option value="always">Sempre</option>
                                <option value="exactly">Apenas respostas exatas</option>
                            </select>
                        </div>
                    </div>
                </section>

                <hr class="line my-0">

                <section class="p-4">
                    <recursive-answers :data="answer" :recursive="recursiveAnswers"/>

                    <div class="row align-items-center justify-content-end">
                        <div class="col-12 col-md-6 col-lg-4 d-flex mt-2">
                            <router-link
                                v-slot="{ navigate }"
                                :to="{ name: 'responses' }"
                                custom
                            >
                                <button class="btn btn-outline-primary flex-grow-1 mr-2" @click="navigate">Cancelar
                                </button>
                            </router-link>
                            <button class="btn flex-grow-1 btn-primary" @click="handleSaveAnswer">Salvar</button>
                        </div>
                    </div>
                </section>
                <short-link-modal />
            </b-card-body>
        </b-card>
    </section>
</template>

<script>
import api from "@/api";
import {
    BCard,
    BCardBody,
    BFormCheckbox
} from "bootstrap-vue";
import Loading from '@/views/components/Loading.vue';
import RecursiveAnswers from './RecursiveAnswers.vue';
import SaveSubjectModal from "./components/SaveSubjectModal.vue";
import ShortLinkModal from '@/views/pages/dashboard/messages/modals/ShortLinkModal.vue';

export default {
    components: {
        BCard,
        BCardBody,
        BFormCheckbox,
        RecursiveAnswers,
        Loading,
        SaveSubjectModal,
        ShortLinkModal
    },
    data() {
        return {
            campaigns: [],
            subjects: [],
            answer: {
                _id: this.$route.params.id,
                subject_id: null,
                campaign_id: null,
                answer_origin: null,
                neddles: [],
                answers: [],
                name: '',
                active: true,
                frequency: null
            },
            recursiveAnswers: false,
            loading: false
        }
    },
    created() {
        this.loading = true;

        let promises = [this.getCampaigns(), this.getSubjects()];

        if (this.answer._id != null) {
            promises.push(this.getAnswer());
        }

        Promise.all(promises).finally(() => this.loading = false);
    },
    methods: {
        toast(message, type = 'danger') {
            if (type === 'error') type = 'danger';
            this.$bvToast.toast(message, {title: 'Respostas', variant: type})
        },
        getAnswer() {
            return api.get(`/answers/${this.answer._id}`)
                .then(response => {
                    const {type, body, message} = response.data;

                    if (type === 'success') {
                        if(body.sequential_count && body.sequential_count > 0) {
                            this.recursiveAnswers = true;
                        }

                        return Object.assign(this.answer, body);
                    }
                    throw message;
                })
                .catch(() => {
                    return this.$swal('Resposta não encontrada.', 'Não foi possível recuperar os dados da resposta, verifique se a resposta informada existe.', 'error')
                        .finally(() => this.$router.replace({name: 'responses'}));
                })
        },
        getSubjects() {
            return api.get('/answers/subjects', {query: {all: 1}})
                .then(response => {
                    const {type, body, message} = response.data;

                    if (type === 'success') {
                        return this.subjects = body;
                    }
                    throw message;
                })
                .catch(() => {
                    this.subjects = [];
                })
        },
        getCampaigns() {
            return api.get('/campaigns', {query: {only: ['_id', 'name']}})
                .then(response => {
                    const {type, body, message} = response.data;

                    if (type === 'success') {
                        return this.campaigns = body;
                    }
                    throw message;
                })
                .catch(() => {
                    this.campaigns = [{
                        _id: null,
                        name: 'Nenhuma campanha encontrada'
                    }]
                })
        },
        handleSaveAnswer() {
            const answer = this.answer;

            if (answer.neddles.length === 0 || answer.name.length === 0) {
                return this.$swal('Resposta incompleta.', 'Adicione ao menos uma variação de texto, uma resposta e um nome para salvar.', 'error');
            }

            if (answer.frequency == null) {
                return this.$swal('Resposta incompleta.', 'Selecione uma opção para as respostas automáticas.', 'error');
            }

            this.loading = true;
            api({
                url: answer._id == null ? '/answers' : `/answers/${answer._id}`,
                method: answer._id == null ? 'POST' : 'PUT',
                data: answer
            })
                .then(response => {
                    const {type, message, body} = response.data;

                    if (type === 'success') {
                        Object.assign(this.answer, body);
                        return this.$swal('Respostas Salvas Com Sucesso!', 'Todos as respostas já estão em seu banco. ', 'success')
                            .finally(() => {
                                this.$router.replace({name: 'responses/edit', params: {id: body._id}});
                            })
                    }
                    this.toast(message, type);
                })
                .catch(error => {
                    let message = 'Não foi possível salvar as respostas';
                    if (error.response) message = error.response.message;

                    this.toast(message);
                })
                .finally(() => this.loading = false)
        }
    },
    watch: {
        recursiveAnswers(value) {
            if (!value) {
                const result = this.answer.answers.filter(e => e.neddles.length > 0).length > 0;
                if (result) {
                    this.$swal({
                        title: "Deseja remover todas as perguntas sequenciais?",
                        showCancelButton: true,
                        cancelButtonText: "Não",
                        confirmButtonText: "Sim",
                        confirmButtonColor: "#317f98",
                    })
                        .then(result => {
                            if (result.isConfirmed) {
                                return this.answer.answers = this.answer.answers.map(e => {
                                    return {...e, neddles: []}
                                });
                            }
                            this.recursiveAnswers = true;
                        })
                } else {
                    this.answer.answers = this.answer.answers.map(e => {
                        return {...e, neddles: []}
                    });
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.small-button {
    font-size: 80%;
    background: none;
}

.blue {
    color: #00a6ff
}

.orange {
    color: #ff9900
}

.btn-outline-info {
    background: none;
}
</style>
