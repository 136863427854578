<template>
    <b-modal
        id="shortLinkModal"
        title="Gerar Link Reduzido"
        body-class="position-unset"
        centered
        content-class="p-4 position-relative"
        footer-border-variant="light"
        header-border-variant="light"
        hide-footer
        lazy
        :no-close-on-backdrop="loading"
        :no-close-on-esc="loading"
        @hide="reset"
    >
        <loading v-if="loading" />
        <div class="row">
            <div class="col">
                <validation-observer ref="shortUrlValidation" tag="form">
                    <label class="text-black-50" for="">Link Original</label>
                    <validation-provider
                        #default="{ failed }"
                        class="w-100"
                        rules="required|url"
                    >
                        <input 
                            v-model="form.long_url"
                            class="form-control mb-3"
                            type="text"
                            :class="{ 'is-invalid': failed }"
                        >
                    </validation-provider>

                    <label class="text-black-50" for="">Titulo</label>
                    <input 
                        v-model="form.title"
                        class="form-control mb-3"
                        type="text"
                    >

                    <label class="text-black-50" for="">Descrição</label>
                    <input 
                        v-model="form.description"
                        class="form-control mb-3"
                        type="text"
                    >

                    <div class="d-flex justify-content-end">
                        <span @click="submit" class="text-blue my-2 font-weight-bold" role="button">
                            Reduzir Link
                        </span>
                    </div>
                </validation-observer>
            </div>
        </div>
        <div v-if="this.shortUrl" class="mt-2">
            <label class="font-weight-bold" for="">Link Reduzido</label><br>
            <a
                id="text"
                :href="this.shortUrl"
                target="_blank"
                style="max-width: 50%;"
            >{{ this.shortUrl }}</a>
            <font-awesome-icon
                @click="copyLink"
                icon="fa-regular fa-copy"
                class="text-secondary mx-2"
            />
        </div>
    </b-modal>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {BModal} from "bootstrap-vue";
import api from "@/api";
import { required, url } from "@/@core/utils/validations/validations";
import Loading from "@/views/components/Loading.vue";

export default {
    components: {
        BModal,
        ValidationObserver,
        ValidationProvider,
        Loading
    },
    data() {
        return {
            form: {
                long_url: null,
                title: null,
                description: null,
            },
            shortUrl: null,
            loading: false,
            text: null,
            required,
            url
        };
    },
    methods: {
        toast(message, type = "danger") {
            this.$root.$bvToast.toast(message, {
                title: 'Encurtar link',
                autoHideDelay: 2000,
                variant: type
            })
        },
        reset() {
            this.form = {
                long_url: null,
                title: null,
                description: null,
            };
            this.shortUrl = null;
        },
        submit() {
            this.$refs.shortUrlValidation.validate().then(success => {
                if(!success) return;

                this.loading = true;
                api.post('/urls', this.form)
                .then(res => {
                    const {type, message, body} = res.data;
                    if (type == 'success') {
                        this.shortUrl = body.short_url;
                        return this.toast(message, 'success');
                    }
                    throw res;
                })
                .catch(error => {
                    let message = 'Não foi possível processar sua requisição corretamente.';
                    if (error.response) {
                        message = error.response.data.message;
                    }
                    this.toast(message);
                })
                .finally(() => this.loading = false);
            })
        },
        copyLink() {
            let link = document.querySelector("#text").innerText;
            navigator.clipboard.writeText(link)
            .then(() => this.toast('Link copiado para área de transferência.', 'success'))
            .catch(() => this.toast('Falha ao copiar para área de transferência.'));
        },
    },
}

</script>


