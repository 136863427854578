<template>
    <div class="tree-branch">
        <div class="tree-leaf">
            <div class="d-flex align-items-start justify-content-between pb-2 tree-flower">
                <div class="d-flex align-items-center">
                    <slot name="control-button">
                        <control-button @click.native="collapseBranch" :active="collapsed"/>
                    </slot>
                    <slot name="control-addon"></slot>
                </div>
                <slot name="control-addon-end"></slot>
            </div>
            <div :class="collapsed ? 'tree-collapsed' : ''">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import ControlButton from '@/views/components/TreeLayout/components/ControlButton.vue';

export default {
    components: {
        ControlButton
    },
    data() {
        return {
            collapsed: true
        }
    },
    methods: {
        collapseBranch() {
            this.collapsed = !this.collapsed;
        }
    }
}
</script>
