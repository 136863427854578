<template>
    <tree-root>
        <template slot="control-addon-end">
            <span v-b-modal.answerTestModal class="link-secondary text-decoration-underline" role="button">Testar</span>
        </template>
        <tree-branch>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4" v-for="(neddle, i) in data.neddles" :key="`answer-pack-neddle-${i}`">
                    <card-text-synonym
                        :index="i + 1"
                        :data="neddle"
                        :show-text="false"
                        :show-toolbar="false"
                        @onDelete="() => data.neddles.splice(i, 1)"
                    ></card-text-synonym>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <div role="button" @click="addTextVariation(data.neddles)" class="h-100 border rounded d-flex align-items-center justify-content-center">
                        <div class="d-flex align-items-center flex-wrap justify-content-center py-3">
                            <span class="circle-icon mx-1">+</span>
                            <span class="mx-1">Adicionar uma variação de texto</span>
                        </div>
                    </div>
                </div>
                <div class="col-1 d-flex align-items-center justify-content-center">
                    <font-awesome-icon
                        icon="fas fa-circle-info"
                        class="mx-5"
                        v-b-tooltip="'Algumas expressões podem ser usadas para aceitar mais variações: \\w (Qualquer texto), \\d (Qualquer número) e * (Qualquer coisa)'"
                    />
                </div>
            </div>
        </tree-branch>
        <tree-branch v-for="(answer, i) in data.answers" :key="`answer-pack-answer-${i}`">
            <template slot="control-addon">
                <span
                    :style="`background-color: ${answer.color}`"
                    class="btn text-white text-uppercase ml-4"
                >
                    {{ answer.condition }}
                </span>
            </template>
            <template slot="control-addon-end">
                    <span 
                        v-b-tooltip="'Remover resposta'" 
                        class="circle-icon" 
                        @click="removeItem(data.answers, i)" 
                        role="button"
                    >
                        -
                    </span>
            </template>
            <tree-root>
                <tree-branch>
                    <div class="row">
                        <div class="col-12 col-lg-10 col-xl-6">
                            <card-text-synonym
                                :data="answer"
                                :show-title="false"
                                :show-delete="false"
                                allow-attachments
                            ></card-text-synonym>
                        </div>
                    </div>
                </tree-branch>
                <tree-branch v-for="(neddle, i) in answer.neddles" :key="`answer-root-needle-${i}`">
                    <template slot="control-addon-end">
                        <span 
                            v-b-tooltip="'Remover réplica'" 
                            class="circle-icon" 
                            @click="removeItem(answer.neddles, i)" 
                            role="button"
                        >
                            -
                        </span>
                    </template>
                    <recursive-answers :data="neddle" :is-child="true" :recursive="recursive"></recursive-answers>
                </tree-branch>
                <tree-branch v-if="recursive">
                    <template slot="control-button">
                        <span class="circle-icon" @click="addNeddle(answer.neddles)" role="button">+</span>
                    </template>
                </tree-branch>
            </tree-root>
        </tree-branch>
        <tree-branch v-if="data.answers.length == 0">
            <template slot="control-button">
                <span class="circle-icon" @click="addAnswer(data.answers)" role="button">+</span>
            </template>
        </tree-branch>
        <answer-test-modal v-if="!isChild" :value="data"/>
    </tree-root>
</template>

<script>
import CardTextSynonym from '@/views/components/CardTextSynonym/index.vue';
import TreeBranch from '@/views/components/TreeLayout/TreeBranch';
import TreeRoot from '@/views/components/TreeLayout/TreeRoot';
import AnswerTestModal from './components/AnswerTestModal.vue';

export default {
    name: 'recursive-answers',
    components: {
        CardTextSynonym,
        TreeBranch,
        TreeRoot,
        AnswerTestModal
    },
    data() {
        return {
            modalVisible: false
        }
    },
    props: {
        data: {
            type: Object,
            default: () => {
                return {
                    neddles: [
                        {
                            title: "Título da resposta",
                            synonyms: []
                        }
                    ],
                    answers: []
                }
            }
        },
        isChild: {
            type: Boolean,
            default: false
        },
        recursive: {
            type: Boolean,
            default: true
        },
    },
    methods: {
        addTextVariation(array) {
            array.push({
                title: "Título da resposta",
                synonyms: []
            })
        },
        addAnswer(array) {
            array.push({
                condition: 'Resposta',
                text: 'Texto da Resposta',
                synonyms: [],
                color: '#317f98',
                attachments: [],
                neddles: []
            })
        },
        addNeddle(array) {
            array.push({
                neddles: [
                    {
                        title: "Título da resposta",
                        synonyms: []
                    }
                ],
                answers: []
            });
        },
        removeItem(array, index) {
            array.splice(index, 1);
        }
    }
}
</script>
