<template>
    <span role="button">
        <font-awesome-icon :icon="`fa-solid ${active ? 'fa-caret-up' : 'fa-caret-down'}`"/>
        <font-awesome-icon icon="fa-solid fa-network-wired" class="ml-2"/>
    </span>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: () => true
        }
    }
}
</script>

<style lang="scss" scoped>
span[role='button'] {
    color: #999;
}
</style>
